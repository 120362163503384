.technologies {
  position: relative;
}

.tech__blur {
  width: 80vw;
  height: 500px;
  margin: auto;
  border-radius: 10px;
  background: var(--purple);
  filter: blur(200px);
  position: absolute;
  left: 6%;
}

.tech__content {
  width: 80vw;
  height: 500px;
  margin: auto;
  border-radius: 10px;
  border: 1px solid var(--purple);
  box-shadow: var(--boxShadow);
}

.tech__content .tech__row {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.tech_row_part {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: absolute;
}

.row_left {
  margin-left: 10%;
}

.animate-1 {
  animation: move 80s linear infinite;
}

.animate-2 {
  animation: move-2 80s linear infinite;
}

@keyframes move {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

@keyframes move-2 {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}
.logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--purple);
}
