@font-face {
  font-family: Gumela;
  src: url("../src/fonts/Gumela\ Regular.otf");
}
* {
  font-family: "Gumela";
  font-weight: normal;
  font-size: 20px;
}
body {
  margin: 0;
}
